import React, { Suspense } from "react";
import {
  Route,
  Switch,
  BrowserRouter as Router,
  withRouter,
} from "react-router-dom";
import Layout from "./components/Layout/";
import Loader from "./components/Loader";
import routes from "./routes";

import "./assets/css/materialdesignicons.min.css";
import "./Apps.scss";
import "./assets/css/colors/default.css";

const withLayout = (WrappedComponent, hasDarkTopBar) => {
  return class extends React.Component {
    render() {
      return (
        <Layout hasDarkTopBar={hasDarkTopBar}>
          <WrappedComponent></WrappedComponent>
        </Layout>
      );
    }
  };
}

const App = () => {
  return (
    <React.Fragment>
      <Router>
        <Suspense fallback={Loader()}>
          <Switch>
            {routes.map((route, idx) =>
              route.isWithoutLayout ? (
                <Route
                  path={route.path}
                  exact={route.exact}
                  component={route.component}
                  key={idx}
                />
              ) : (
                <Route
                  path={route.path}
                  exact
                  component={withLayout(route.component, route.isTopbarDark)}
                  key={idx}
                />
              )
            )}
          </Switch>
        </Suspense>
      </Router>
    </React.Fragment>
  );
}

export default withRouter(App);
