import React, { Suspense } from "react";
import { withRouter } from "react-router-dom";
import Loader from "../Loader";

//Import Icons
// import FeatherIcon from "feather-icons-react";

// Layout Components
const Topbar = React.lazy(() => import("./Topbar"));
const Footer = React.lazy(() => import("./Footer"));

// const CustomDot = () => {
//   return (
//     <React.Fragment>
//       <FeatherIcon icon="arrow-up" className="icons" />
//     </React.Fragment>
//   );
// };

const Layout = (props) => (
    <React.Fragment>
      <Suspense fallback={Loader()}>
        <Topbar hasDarkTopBar={props.hasDarkTopBar} />

        {props.children}
        <Footer />

        {/* <ScrollUpButton
          AnimationDuration={500}
          ContainerClassName="classForContainer"
          style={{ height: 36, width: 36 }}
          TransitionClassName="classForTransition"
        >
          <CustomDot />
        </ScrollUpButton> */}

      </Suspense>
    </React.Fragment>
);

export default withRouter(Layout);