import React from "react";

const Main = React.lazy(() => import("./pages/Home/indexMain"));
const Privacy = React.lazy(() => import("./pages/Privacy/index"));
const StockiePrivacy = React.lazy(() => import("./pages/Privacy/StockiePrivacy"));
const PageTerms = React.lazy(() => import("./pages/Pages/Utility/PageTerms"));

//Special
const PageError = React.lazy(() => import("./pages/Pages/Special/PageError"));
const PageMaintenance = React.lazy(() =>
  import("./pages/Pages/Special/PageMaintenance")
);

const routes = [
  { path: "/page-error", component: PageError, isWithoutLayout: true },
  {
    path: "/page-maintenance",
    component: PageMaintenance,
    isWithoutLayout: true,
  },
  { path: "/page-terms", component: PageTerms, isTopbarDark: true },
  { path: "/privacy", component: Privacy, isTopbarDark: true },
  { path: "/privacy/stockie", component: StockiePrivacy, isTopbarDark: true },
  { path: "/", component: Main, isTopbarDark: true, exact: true },
  { component: PageError, isWithoutLayout: true, exact: false },
];

export default routes;
